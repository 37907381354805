import React, { useState } from 'react';
import "../whyus.css";
import whyusapi from '../whyus.js';
const Whyus = () => {
    const [serviceData, setServiceData] = useState(whyusapi);
    
    return (  
        <>
        <section className="service-main-container">
          <div className="container service-container">
            <h1 className="main-heading text-center fw-bold">
             Why choose TrueSand?
            </h1>
            <div className="row">
              {serviceData.map((curElem) => {
                const { id, image, title, info } = curElem;
                return (
                  <>
                    <div
                      className="col-11 col-lg-4 col-xxl-4 work-container-subdiv"
                      key={id}>
                       <img src={image} alt="" />
                      <h2 className="sub-heading">{title}</h2>
                      <p className="main-hero-para">{info}</p>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </section>
      </>
  );
}
 
export default Whyus;
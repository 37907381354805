import image1 from"./images/quality.jpg";
import image2 from"./images/fastdelivery.jpg";
import image3 from"./images/bestprice.png";
const whyusapi = [
    {
      id: 1,
      image: image1,
      title: "Trusted quality ",
      info: "Our Eco-friendly River Sand is sourced from trusted locations and undergoes rigorous quality checks to ensure it meets industry standards.",
    },
    {
      id: 2,
      image: image2,
      
      title: "Fast delivery ",
      info: "We pride ourselves on punctual deliveries, ensuring your project stays on schedule.",
    },
    {
      id: 3,
      image: image3,
      title: "Best price in the market",
      info: "Unbeatable value, lowest cost, top-tier quality - market's best price.",
    },
    
  ];
  
  export default whyusapi;
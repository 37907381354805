import React from 'react';
import SimpleImageSlider from "react-simple-image-slider";
import "../services.css";
import Navbar from './Navbar';
import Whyus from './whyus';
import { Carousel } from 'react-responsive-carousel';
import Slider from './Slider';
import { slidesData } from "../slidesData";
import miningprocess from "../images/miningprocess.gif";
import Footer from './Footer';
function Riversand() {
  const images = [
    {
      url: "https://www.buildmaadi.com/wp-content/uploads/2020/05/Plastering-M-Sand.jpg",
    },
    { url: "https://vignesharavindtransports.com/wp-content/uploads/2018/10/1_vg-1024x454.jpg" },
    { url: "https://www.buildmaadi.com/wp-content/uploads/2020/05/Plastering-M-Sand.jpg" },
    { url: "https://media.istockphoto.com/id/1011442214/photo/heavy-machinery-at-the-quarry.jpg?s=2048x2048&w=is&k=20&c=L2SCiKWL_3hReJynPlydFXB8kZTKgOpPfvjp_6ex4S8=" },
    { url: "../images/msandresized.jpg" },
    { url: "images/6.jpg" },
    { url: "images/7.jpg" },
  ];
    return ( 
        <>
        <Navbar />
         <div className="services">
        <div className="services_title">

        <h1>What is River sand?</h1>
        </div>
        <div className="services_desc">
        <p>
        River sand, also known as natural sand, is a fine sand that is commonly found around streams and river banks. River sand's softness makes it suitable for use in concrete and brickwork. Plastering, RCC, and a variety of other block works benefit from river sand.

          </p>
          <p>
            True Sand Guarentees you the best Riversand in the market which is of
            the finest quality and very reliable.
          </p>
        </div>
        <div className="services_images_container">
      
       <Slider slides={slidesData} />
        </div>

        <div className="miningprocess">
          <h1 className='main-heading text-center fw-bold'>Our Mining Process</h1>
          <hr style={{height: "9px", backgroundColor:"white", position:"relative", top:"3px"}} />
          <img src={miningprocess} alt="" />
        </div>
        <Whyus />
        
      </div>
      <Footer />
        </>
     );
}

export default Riversand;
import React from "react";
import Home from "./Home";
import {Route, Routes} from "react-router-dom";
import Contact from "./Components/Contact";
import Msand from "./Components/Msand";
import Riversand from "./Components/Riversand";
import {
  HashRouter as Router,
  } from 'react-router-dom';

function App() {
  return(
    <>
<Routes>
    <Route path="/" Component={Home}></Route>
    <Route path="/contact" Component={Contact}></Route>
    <Route path="/msand" Component={Msand}></Route>
    <Route path="/riversand" Component={Riversand}></Route>
  
</Routes>
    </>
  );
}

export default App;

import React from "react";
import "../contact.css";
import { IoCallOutline } from "react-icons/io5";
import { FaLocationArrow } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import  { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Navbar from "./Navbar";
import Footer from "./Footer";


function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_lsltiz6', 'template_rkhwtbj', form.current, '5mq6ralmS1TpEzsni')
      .then((result) => {
          console.log(result.text);
          e.target.reset();
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <>
    <Navbar />
      <div className="contact_info">
        <h1>Contact Us</h1>
        {/* <h6 >Ready to order or have questions? Contact our friendly team today to discuss your river sand needs. We're here to assist you every step of the way.</h6> */}
        

          <div className="rowitems">
            <div className="phone">

              <h1>
                <IoCallOutline />
              </h1>
              <h2>Phone</h2>
              <p>+91 95911 19139</p>
              <p>+91 90718 02988</p>
            </div>
            <div className="address">
             <h1>

            <FaLocationArrow/>
             </h1>
              <h2>Address</h2>
              <p>
                Horizon Ventures, No. 4, Anaga, 1st floor, Above Lenskart, New
                BEL Road, Bengaluru, Karnataka 560094, India
              </p>
            </div>
            <div className="email">
              <h1>

              <HiOutlineMail/>
              </h1>
              <h2>Email</h2>
              <p>sales@truesand.in</p>
            </div>
          </div>
       
      </div>
      <div className="contactform">
        <form ref={form} onSubmit={sendEmail}>
         
          <input type="text" name="user_name" placeholder="Name" />
          <input type="text" name="user_number" placeholder="Phone Number" />
          
          <input type="email" name="user_email" placeholder="Email"/>
          
          <textarea rows="4" cols="7" name="message" placeholder="Message" />
          <button className="Submitbutton">Send</button>
        </form>
      </div>
      <div className="location">
        <h2>Our Location</h2>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.1254091555297!2d77.56898417510925!3d13.027684913646132!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae176ecd388021%3A0xbd8cb560954d8c9b!2sHorizon%20Ventures!5e0!3m2!1sen!2sin!4v1694419225950!5m2!1sen!2sin" width="1000" height="450" style={{border:0}} allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>

      <Footer />
    </>
  );
}

export default Contact;

import React from 'react';
function About(props) {
    return (  <>
        <h1 className="main-heading text-center fw-bold"> How to Use? </h1>
    <div id="about">
        <div className="about-image">
            <img src={props.image} alt="" />
        </div>
        <div className="about-text">
            <h2>{props.title}</h2>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsam dolorem minus totam eius sint reiciendis fuga voluptates, nulla distinctio exercitationem quo, temporibus excepturi dolores quisquam!</p>
            <button>{props.button}</button>
        </div>
    </div>
    </>);
}

export default About;
export const slidesData = [
    {
      id: "001",
      img: "https://media.istockphoto.com/id/1186882319/photo/sand-texture-river-sand-background-wallpaper-design-element-background.jpg?s=612x612&w=0&k=20&c=HiSGc-Kh_I7k9ekxAgJnxp_sjnPeOj69y5Y8_JuL2so=",
      title: "Highest Quality Riversand",
      cta: "BOOK NOW",
    },
    {
      id: "002",
      img: "https://vignesharavindtransports.com/wp-content/uploads/2018/10/1_vg-1024x454.jpg",
      title: "Certified with Lab tests",
      cta: "BOOK NOW",
    },
    {
      id: "003",
      img: "https://t3.ftcdn.net/jpg/02/17/69/08/360_F_217690851_NbVilgEc7SeLpE2Nex9iavtlaA0Zu8E8.jpg",
      title: "Delivered in 2 days",
      cta: "BOOK NOW",
    },
  ];
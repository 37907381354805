import React from "react";
import SimpleImageSlider from "react-simple-image-slider";
import "../services.css";
import Navbar from "./Navbar";
import Whyus from "./whyus";
const Msand = () => {
  const images = [
    {
      url: "https://www.buildmaadi.com/wp-content/uploads/2020/05/Plastering-M-Sand.jpg",
    },
    { url: "https://vignesharavindtransports.com/wp-content/uploads/2018/10/1_vg-1024x454.jpg" },
    { url: "https://www.buildmaadi.com/wp-content/uploads/2020/05/Plastering-M-Sand.jpg" },
    { url: "https://media.istockphoto.com/id/1011442214/photo/heavy-machinery-at-the-quarry.jpg?s=2048x2048&w=is&k=20&c=L2SCiKWL_3hReJynPlydFXB8kZTKgOpPfvjp_6ex4S8=" },
    { url: "../images/msandresized.jpg" },
    { url: "images/6.jpg" },
    { url: "images/7.jpg" },
  ];

  return (
    <>
      <div className="services">
       <nav className="nav">

    <Navbar />
       </nav>
    
      <div className="services_title">

<h1>M-Sand</h1>
</div>
        <div className="services_desc">
        <p>
            M Sand - M sand is used as a substitute for river sand for
            construction purposes. It is a crushed aggregates produced from hard
            rock stone which is cubically shaped with grounded edges and other
            characteristics to replace the requirement of river sand.
          </p>
          <p>
            True Sand Guarentees you the best M-sand in the market which is highly reliable and is of the highest quality at an affordable price.
          </p>
        </div>
        <div className="services_images_container">
          <SimpleImageSlider
            width={1514}
            height={704}
            images={images}
            showBullets={true}
            showNavs={true}
          />
        </div>
        <Whyus />

        
      </div>
    </>
  );
};

export default Msand;

import React from 'react';
import ServiceBox from './ServiceBox';
import service1 from '../images/riversandwithhand.jpg'
import service2 from '../images/msandwithhand.jpg'
import service3 from '../images/psandwithhand.webp'
import service4 from '../images/readymixconcrete.jpg'
import service5 from '../images/40-mm-Coarse-Aggregate.png'
import service6 from '../images/dust.jpg'
function Feature() {
    return (  
        <>
        <div id="features">
        <h1 className="main-heading text-center fw-bold">
           Our Services
            </h1>
            <div className="a-container">
                <ServiceBox image={service1} title='River Sand' desc ="
River sand is an essential building material known for its stability and durability. It's ideal for various construction purposes."/>
                {/* <ServiceBox image={service2} title='M Sand'desc ="Manufactured sand (M-sand): construction, concrete production, fine aggregate replacement, sustainability."/> */}
                <ServiceBox image={service3} title='P Sand' desc ="Plaster Sand is used with cement, and sometimes lime, to make mortar for masonry work and plaster."/>
                <div className="break"></div>
                {/* <ServiceBox image={service4} title='Ready Mix Concrete'desc ="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Esse, voluptatum." />
                <ServiceBox image={service5} title='Aggregates'desc ="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Esse, voluptatum." />
                <ServiceBox image={service6} title='Dust'desc ="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Esse, voluptatum." /> */}
              
                
            </div>
        </div>
        </>
    );
}

export default Feature;
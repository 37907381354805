import React from "react";
import styled from "styled-components";
import {  FaInstagram, FaYoutube ,FaTwitter } from "react-icons/fa";
import '../footer.css'

const Footer = () => {
  return (
  

      <footer>
        <div className="container grid grid-four-column">
          <div className="footer-about">
            <h3>True Sand</h3>
            <p>Quality River Sand Dealers in Bangalore, your trusted source for premium river sand across Karnataka.</p>
          </div>

          {/* 2nd column */}
          <div className="footer-address">
            <h3>Our Address</h3>
            <p>Horizon Ventures, No. 4, Anaga, 1st floor, Above Lenskart, New BEL Road, Bengaluru, Karnataka 560094, India</p>
          </div>

          {/* 3rs column  */}
          <div className="footer-social">
            <h3>Follow Us</h3>
            <div className="footer-social--icons">
              <div>
              <a
                  href="https://twitter.com/horizonventure5"
                  target="_blank">
                  <FaTwitter className="icons" />
                </a>
              </div>
              <div>
                <FaInstagram className="icons" />
              </div>
              <div>
                <a
                  href="https://youtube.com/@HorizonVentures?feature=shared"
                  target="_blank">
                  <FaYoutube className="icons" />
                </a>
              </div>
            </div>
          </div>

          {/* 4th column  */}
          <div className="footer-contact">
            <h3>Call Us</h3>
            <h3>+91 95911 19139</h3>
            <h3>+91 90718 02988</h3>
          </div>
        </div>

        {/* bottom section  */}
        <div className="footer-bottom--section">
          <hr />
          <div className="container grid grid-two-column">
            <p>
              @{new Date().getFullYear()} TrueSand. All Rights Reserved
            </p>
           
          </div>
        </div>
      </footer>
   
  );
};



export default Footer;
import React from 'react';
import Header from "./Components/Header";
import Feature from "./Components/Feature";
import About from "./Components/About";
import stepsimage from "./images/TrueSand_steps.png"
import Footer from "./Components/Footer";
import Whyus from "./Components/whyus";
import Carousel from './Components/Carousel';
function Home() {
    return (
        <div className="App">
          <Header/>
          <Feature />
          <Whyus />
          {/* <Carousel /> */}
          {/* <About image={stepsimage} title="Simple steps to use our App" button="Get the App"/> */}
          <Footer />
          
          </div>
      );
}

export default Home;
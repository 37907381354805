export const items = [
    {
        status: "inactive",
        imgSrc: "",
        name: "Matrix homes",
        position: "Syed",
        quote: "The river sand was sourced sustainably and was environmentally friendly. Knowing that I was using a product that had minimal impact on the ecosystem was a significant plus for me."
    },
    {
        status: "inactive",
        imgSrc: "",
        name: "Ham X builders",
        position: "Landscape Engineer", 
        quote: " I was impressed by the excellent drainage properties of this river sand. It allowed water to percolate through it efficiently, preventing any issues with standing water or drainage problems. This was crucial for maintaining the health of my plants and preventing erosion in my landscaping."
    },
    {
        status: "inactive",
        imgSrc: "",
        name: "Sattva",
        position: "Developer",
        quote: "The river sand was sourced sustainably and was environmentally friendly. Knowing that I was using a product that had minimal impact on the ecosystem was a significant plus for me."
    },

];

export default items;
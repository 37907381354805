import React from 'react';
import Navbar from './Navbar';
import video from "../images/Truesandvideo.mp4";
import badge from '../images/deliverybadge.png';
function Header() {
    return (  
        <>
        <div id="main">

        <Navbar />
        </div>
        <div className="name">
            <h1><span>River Sand delivery made easy</span> with TrueSand</h1>
            <p className="details">Whether you're a builder, contractor, or homeowner, we've got your river sand needs covered.</p>
            <a href="/contact" className="cv-btn">Call Now</a>
            <img src={badge} alt="" />
        </div>
        <div className="videopage">

            <video src={video} width="100%" height="100%" loop autoPlay muted  />
        </div>
        </>
    );
}

export default Header;